<template>
  <div :class="['activityList', listProduct.length == 0 ? 'emptyList' : '']">
    <ul class="list">
      <li
        class="item"
        v-for="(item, index) in listProduct"
        :key="index"
        @click="getProductDetails(item.ProductNumber, item.ShopId)"
      >
        <van-image
          class="goodsImg"
          :src="item.ProductPhoto && imgUrlFilter(item.ProductPhoto)"
        >
          <template v-slot:error>
            <img class="ImgIcon" src="~assets/img/other/empty.gif" />
          </template>
        </van-image>
        <div class="itemDesc">
          <div class="discount" v-if="item.IsCouponProduct">
            {{ $t.discount }}
          </div>
          <div class="brand" v-if="item.IsBrandProduct">
            {{ $t.brand }}
          </div>
          <div class="attestation" v-if="item.IsAttestationProduct">
            {{ $t.attestation }}
          </div>
          <div class="name">{{ item.ProductName }}</div>
        </div>
        <div class="price" v-if="item.MaxPrice == item.MinPrice">
          <span class="unit">$</span>
          {{ item.MaxPrice }}
        </div>
        <div class="price" v-else>
          <span class="unit">$</span>
          {{ Math.round(item.MinPrice * 100) / 100 }}
        </div>
        <!-- <p class="itemSales">{{ item.Sales }}{{ $t.homeList.Sold }}</p> -->
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Image } from "vant";

Vue.use(Image);
export default {
  name: "ActivityList",
  props: ["listProduct"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.emptyList {
  height: 0;
}
.activityList {
  margin-top: 15px;
  .list {
    width: 100%;
    @include publicUl;
    .item:last-child {
      margin-right: 2.7vw;
    }
    .item {
      position: relative;
      display: inline-block;
      //width: 100px;
      width: 30vw;
      background-color: #ffffff;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 2.7vw;
      @include publicBoxRadio;
      .goodsImg {
        height: 28.5vw;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        object-fit: cover;
        .ImgIcon {
          height: 100%;
          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          object-fit: cover;
        }
        ::v-deep .van-image__img {
          height: 100%;
          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          object-fit: cover;
        }
        ::v-deep .van-image__error {
          height: 100%;
          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          object-fit: cover;
        }
      }
      .itemDesc {
        margin-top: 7px;
        padding: 0 9px;
        height: 50px;
        font-size: 12px;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
        white-space: normal;
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
          margin-bottom: 2px;
        }
        .brand {
          vertical-align: middle;
          display: inline-block;
          background: #e6a23c;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
          margin-bottom: 2px;
        }
        .attestation {
          vertical-align: middle;
          display: inline-block;
          background: #22b196;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
          margin-bottom: 2px;
        }
        .name {
          vertical-align: middle;
          display: inline-block;
        }
      }
      .itemSales {
        margin: 0;
        margin-top: 9px;
        padding: 0 9px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        font-size: 11px;
        padding-bottom: 11px;
        color: #a7a7a7;
      }
      .price {
        font-size: 15px;
        color: #ff5852;
        font-weight: bold;
        padding: 0 9px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        line-height: 15px;
        height: 14px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .unit {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
