<template>
  <div class="detailsTitle">
    <div class="detailsTitleBox">
      <section class="info">
        <div class="title">
          <div class="discount" v-if="productInfo.IsCouponProduct">
            {{ $t.discount }}
          </div>
          <div class="brand" v-if="productInfo.IsBrandProduct">
            {{ $t.brand }}
          </div>
          <div class="attestation" v-if="productInfo.IsAttestationProduct">
            {{ $t.attestation }}
          </div>
          <span class="name" v-if="!productInfo.TProductName">{{
            productInfo.ProductName
          }}</span
          ><span class="name" v-if="productInfo.TProductName">{{
            productInfo.TProductName
          }}</span>
        </div>
        <img
          v-if="productInfo.IsFavorite === 1"
          class="logo"
          src="~assets/img/details/icon_detail_like_select@2x.png"
          @click="addFavoriteProduct(0)"
        />
        <img
          v-else
          class="logo"
          src="~assets/img/details/icon_detail_like@2x.png"
          @click="addFavoriteProduct(1)"
        />
        <van-button
          class="detail-share"
          @click="shareGoods"
          v-if="isApp && platform == 'ios'"
        >
          <van-icon name="share" size="20" />
        </van-button>
      </section>
      <section class="priceBox">
        <span class="unit">$</span
        >{{ Math.round(productInfo.MinPrice * 100) / 100 }}
      </section>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon, Button } from "vant";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

Vue.use(Icon).use(Button);
export default {
  name: "DetailsTitle",
  props: ["productInfo", "shopInfo"],
  data() {
    return {
      isApp: isAPP(),
      platform: getPlatform(),
    };
  },
  methods: {
    // 收藏
    addFavoriteProduct(IsFavorite) {
      this.$emit("addFavoriteProduct", IsFavorite);
    },
    //转发
    shareGoods() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.shareGoods, {
        name: this.productInfo.ProductName,
        image: this.productInfo.ProductPhoto.split("|")[0],
        shareUrl:
          "https://shop.vancheen.com/web/details?ProductNumber=" +
          this.productInfo.ProductNumber +
          "&ShopId=" +
          this.shopInfo.ShopId +
          "&BackHome=true",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.detailsTitle {
  width: 100%;
  margin-top: 10px;
  .detailsTitleBox {
    margin: 0 auto;
    position: relative;
    min-height: 90px;
    background-color: #ffffff;
    padding: 12px 22px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 8px;
    width: 92%;
    .info {
      @include publicFlex;
      .title {
        flex: 1;
        -webkit-flex: 1;
        font-size: 15px;
        color: #000000;
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 12px;
          margin-right: 5px;
        }
        .brand {
          vertical-align: middle;
          display: inline-block;
          background: #e6a23c;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 12px;
          margin-right: 5px;
        }
        .attestation {
          vertical-align: middle;
          display: inline-block;
          background: #22b196;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 12px;
          margin-right: 5px;
        }
        .name {
          vertical-align: middle;
          display: inline-block;
        }
      }
      .logo {
        width: 31px;
        height: 31px;
        margin-left: 6px;
      }
      .detail-share {
        width: 31px;
        height: 31px;
        margin-left: 10px;
        border-radius: 10px;
        background: #e5e5e5;
        border-color: #e5e5e5;
        color: #999;
      }
    }
    .priceBox {
      width: 100%;
      font-size: 15px;
      font-weight: bold;
      color: #ff5852;
      margin-top: 9px;
      .unit {
        margin-right: 3.5px;
      }
    }
  }
}
</style>
